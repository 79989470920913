import React from 'react';

import './Footer.css';
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <footer>
            <section>
                <img src="/img/bs.jpg" alt="Advertisement for bitskins" 
                     className="ad"/>
                <div className="statistics">
                    <div className="statistics__wrapper">
                        <div className="stats-box positive_feedbacks">
                            <div className="status green">
                                <img src="/img/arrow.svg" alt="Positive Feedback" />
                            </div>
                            <div className="stats">
                                <div className="number">64966</div>
                                <div className="title">POSITIVE FEEDBACKS</div>
                            </div>
                        </div>
                        <div className="stats-box banned_users">
                            <div className="status red">
                                <img src="/img/cross.svg" alt="Banned Users" />
                            </div>
                            <div className="stats">
                                <div className="number">670</div>
                                <div className="title">BANNED USERS</div>
                            </div>
                        </div>
                        <div className="stats-box users_registered">
                            <div className="status blue">
                                <img src="/img/user.svg" alt="Users Registered" />
                            </div>
                            <div className="stats">
                                <div className="number">41220</div>
                                <div className="title">USERS REGISTERED</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="upper-footer">
                    <div className="left">
                        <img src="/img/logo.svg" 
                             alt="CSGO-Rep logo"
                             height="60"/>
                        <p>
                            Website to keep track of your cash trades, find trustworthy
                            users to do transactions with and avoid risky trades by easily 
                            checking someone's transaction history. Founded in 2020.
                        </p>
                    </div>
                    <div className="middle">
                        <h4>Menu CSGOREP</h4>
                        <ul>
                            <li>
                                <Link to="/">
                                    <img src="/img/li.svg" height="10" 
                                         alt="list element caret"
                                         style={{marginRight: '8px'}}/>
                                    Browse
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <img src="/img/li.svg" height="10"
                                         alt="list element caret" 
                                         style={{marginRight: '8px'}}/>
                                    Profile
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <img src="/img/li.svg" height="10"
                                         alt="list element caret" 
                                         style={{marginRight: '8px'}}/>
                                    Report
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <img src="/img/li.svg" height="10"
                                         alt="list element caret" 
                                         style={{marginRight: '8px'}}/>
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <img src="/img/li.svg" height="10"
                                         alt="list element caret" 
                                         style={{marginRight: '8px'}}/>
                                    Changelog
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <img src="/img/li.svg" height="10"
                                         alt="list element caret" 
                                         style={{marginRight: '8px'}}/>
                                    Contact
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <img src="/img/li.svg" height="10"
                                         alt="list element caret" 
                                         style={{marginRight: '8px'}}/>
                                    New Feedback
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="right">
                        <img src="/img/weapons.png" 
                             alt="CSGO Weapons" 
                             width="450"/>
                    </div>
                </div>
                <div className="lower-footer">
                    <p>
                        <strong>v1.12</strong>
                        CSGOrep 2020. Powered by Steam. Not affiliated with Valve Corp.
                    </p>
                    <a className="twitter" href="https://twitter.com/CSGORepCom" target="_BLANK" rel="noreferrer">
                        <img src="/img/twitter_footer.svg" alt="Twitter" />
                        <span>@CSGORepCom</span>
                    </a>
                </div>
            </section>
        </footer>
    )
}