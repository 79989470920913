import React from 'react';

import './Filter.css';

export const Filter: React.FC = () => {
    return (
        <section className="filter">
            <h2>Transaction history</h2>
            <div className="filter-options">
                <p className="mb-0">Filter:</p>
                <div className="icon-wrap icon-active">
                    <img src="/img/cross.svg" height="25" width="25" alt="Cross" />
                </div>
                <div className="icon-wrap">
                    <img src="/img/buff.svg" height="25" width="25" alt="Buff" />
                </div>
                <div className="icon-wrap">
                    <img src="/img/btc.svg" height="25" width="25" alt="Bitcoin" />
                </div>
                <div className="icon-wrap">
                    <img src="/img/cash.svg" height="25" width="25" alt="Cash" />
                </div>
            </div>
            <div className="sort-by">
                <p>Sort By:</p>
                <select>
                    <option value="date-desc">Date Descending</option>
                    <option value="date-asc">Date Ascending</option>
                    <option value="price-asc">Price Ascending</option>
                    <option value="price-desc">Price Descending</option>
                </select>
            </div>
        </section>
    )
}