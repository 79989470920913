import React from 'react';

import './Home.css';
import { Search } from '../../components/search/Search';
import { Filter } from '../../components/filter/Filter';
import { Separator } from '../../components/separator/Separator';
import useGetFeedback from '../../hooks/useGetFeedback';
import Feedback from '../../components/feedback/Feedback';

export const Home: React.FC = () => {

    const { feedbacks, isLoading } = useGetFeedback();

    return (
        <section className="home">
            <Search />
            <br /><br />
            <Separator />
            <Filter />
            {!isLoading && <Feedback feedbacks={feedbacks} />}
            {isLoading && <p>Loading</p>}
        </section>
    )
}