import { useState, useEffect } from 'react';
import { UserInfo } from '../interfaces/user';

const useGetUserInfo = (userId: string) => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/user/${userId}`);
                const data = await response.json();
                console.log(data);

                setIsLoading(false);
                setUserInfo(data);
            } catch (error: any) {
                setIsLoading(false);
                setError(error?.data?.message || error?.message || "Something went wrong");
            }
        };

        fetchData();
    }, [userId]);

    return { userInfo, isLoading, error };
};

export default useGetUserInfo;