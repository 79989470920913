import { useState, useEffect } from 'react';
import { UserFeedback } from '../interfaces/feedback';

const useGetUserFeedback = (userId: string) => {
    const [userFeedback, setUserFeedback] = useState<UserFeedback | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/feedback/${userId}`);
                const data = await response.json();

                setIsLoading(false);
                setUserFeedback(data);
            } catch (error: any) {
                setIsLoading(false);
                setError(error?.data?.message || error?.message || "Something went wrong");
            }
        };

        fetchData();
    }, [userId]);

    return { userFeedback, isLoading, error };
};

export default useGetUserFeedback;