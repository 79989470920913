import React, { Suspense, lazy } from 'react';
import './App.css';
import { Navbar } from './components/navbar/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Footer } from './components/footer/Footer';
import { Home } from './pages/home/Home';
import { Profile } from './pages/profile/Profile';

// Lazy load the Admin component
const Admin = lazy(() => import('./pages/admin/Admin'));
const Bots = lazy(() => import('./pages/admin/bots/Bots'));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/bots" element={<Bots />} />
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>  
    </div>
  );
}

export default App;