import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Search.css';

export const Search: React.FC = () => {
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e: any) => {
        e.preventDefault(); 
        navigate(`/profile/${inputValue}`)
    };

    return (
        <div className="search-wrap">
            <h2>Search user</h2>
            <form className="input-wrap" onSubmit={handleSubmit}>
                <input type="text" 
                       className="search-input" 
                       placeholder="SteamID64"
                       value={inputValue}
                       onChange={(e) => setInputValue(e.target.value)} />
                <button className="search-btn" type="submit">
                    <img src="/img/magnifier.svg" alt="Magnifying glass" />
                </button>
            </form>
            <div className="enter-wrap">
                <span className="enter-text">
                    Enter SteamID64 here
                </span>
                <img src="/img/curve_arrow.svg" 
                     alt="curved arrow" />
            </div>
        </div>
    )
}