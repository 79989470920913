import React, { useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';

import './Navbar.css';
import { useAuthContext } from "../../hooks/useAuthContext";

export const Navbar = (): JSX.Element => {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const location = useLocation().pathname;
    const { user } = useAuthContext();
    const navigate = useNavigate();

    const isActive = (path: string): string => {
        return location === path ? 'nav-link-active' : '';
    };

    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const closeNavbar = () => {
        setIsNavbarOpen(false);
    };

    return (
        <header className="nav-wrap">
            <div className="container">
                <nav className="navbar">
                    {/* Logo */}
                    <Link className="navbar-brand" to="/" onClick={closeNavbar}>
                        <img src="/img/logo.svg" alt="Logo" height="54" width="184"/>
                    </Link>

                    {/* Hamburger Icon */}
                    <div className={`burger-box ${isNavbarOpen ? 'open' : ''}`} onClick={toggleNavbar}>
                        <span className="burger-bar"></span>
                        <span className="burger-bar"></span>
                        <span className="burger-bar"></span>
                    </div>

                    {/* Nav Links */}
                    <ul className={`nav-links ${isNavbarOpen ? 'open' : ''}`}>
                        {user && user.role === 'admin' && <li className={`nav-item ${isActive('/admin')}`}>
                            <Link className="nav-link text-red" to="/admin" onClick={closeNavbar}>Admin</Link>
                        </li>}
                        <li className={`nav-item ${isActive('/')}`}>
                            <Link className="nav-link" to="/" onClick={closeNavbar}>Browse</Link>
                        </li>
                        <li className={`nav-item ${isActive('/faq')}`}>
                            <a className="nav-link" href="https://csgo-rep.com/faq" rel="noreferrer" onClick={closeNavbar}>FAQ</a>
                        </li>
                        <li className={`nav-item ${isActive('/contact')}`}>
                            <a className="nav-link" href="https://csgo-rep.com/contact" rel="noreferrer" onClick={closeNavbar}>Contact</a>
                        </li>
                        {!user ? <li className="nav-item">
                            <a href={`${process.env.REACT_APP_API_URL}/api/v1/auth/steam`} className="d-flex align-items-center">
                                <img src="/img/steam.png" alt="Steam sign in banner" height="35"/>
                            </a>
                        </li>:
                        <li className="nav-item">
                            <div className="user-info">
                                <img src={user.avatar} alt="User avatar" height="45" width="45" onClick={() => navigate('/profile/' + user.steamid64)}/>
                                <div className="name-wrap">
                                    <span>{user.personaname}</span>
                                    <a href={`${process.env.REACT_APP_API_URL}/api/v1/auth/logout`}>logout</a>
                                </div>
                            </div>
                        </li>}
                    </ul>
                </nav>
            </div>
        </header>
    );
}