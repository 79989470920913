import { useState, useEffect } from 'react';

const useGetFeedback = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/feedback?page_size=30`);
                const data = await response.json();

                setIsLoading(false);
                setIsSuccess(true);
                setFeedbacks(data);
            } catch (error: any) {
                setIsLoading(false);
                setError(error?.data?.message || error?.message || "Something went wrong");
            }
        };

        fetchData();
    }, []);

    return { feedbacks, isLoading, error, isSuccess };
};

export default useGetFeedback;
