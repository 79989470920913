import React from 'react';

export const Separator: React.FC = () => {
    return (
        <section className="separator-wrap">
            <hr className="separator" 
                style={{
                    border: 'none',
                    borderTop: '1px solid #333',
                }}/>
        </section>
    )
}