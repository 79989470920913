import React from 'react';
import { Feedback as FeedbackInterface } from '../../interfaces/feedback';

import './Feedback.css'

interface FeedbackProps {
    feedbacks: FeedbackInterface[];
}

const Feedback: React.FC<FeedbackProps> = ({ feedbacks }) => {
    return (
        <section className="feedback-all-wrap">
            {feedbacks.map((feedback) => (
                <>
                    <p className="top-name">{feedback.giver_name}</p>
                    <div key={feedback.id} className="feedback-wrap">
                        <img src={feedback.giver_avatar} 
                             alt="user"
                             className="avatar"
                             height="100"
                             width="100"/>
                        <div className="link-wrap">
                            {feedback.type === 'cash' && (
                                <img src="/img/cash.svg" height="25" width="25" alt="Cash" />
                            )}
                            {feedback.type === 'crypto' && (
                                <img src="/img/btc.svg" height="25" width="25" alt="Bitcoin" />
                            )}
                            {feedback.type === 'buff' && (
                                <img src="/img/buff.svg" height="25" width="25" alt="Buff" />
                            )}
                        </div>
                        <div className="feedback-info">
                            <div>
                                <div className="arrow-wrap">
                                    <img src="/img/arrow.svg" height="15" width="15" alt="arrow" />
                                </div>
                            </div>
                            <div>
                                <p className="feedback-text"><strong>Transaction:</strong> Bought balance for <span className="text-green">${feedback.transaction_value.toFixed(0)}</span> by <span className="text-green">{feedback.type}</span></p>
                                <p className="message">{feedback.message}</p>
                            </div>
                        </div>
                        <p className="date">{(new Date(feedback.created_at)).toLocaleString()} | #{feedback.id}</p>
                    </div>
                </>
            ))}
        </section>
    );
};

export default Feedback;
