import { useParams } from 'react-router-dom';

import './Profile.css';
import { Separator } from '../../components/separator/Separator';
import { Filter } from '../../components/filter/Filter';
import Feedback from '../../components/feedback/Feedback';
import useGetUserInfo from '../../hooks/useGetUserInfo';
import useGetUserFeedback from '../../hooks/useGetUserFeedback';

export const Profile = () => {
    const { id } = useParams();

    const { userInfo, isLoading: isUserInfoLoading, error: userInfoError } = useGetUserInfo(id ?? '');
    const { userFeedback, isLoading: isUserFeedbackLoading, error: userFeedbackError } = useGetUserFeedback(id ?? '');

    if (isUserInfoLoading || isUserFeedbackLoading) {
        return <div style={{marginTop: '10rem', color: '#fff', marginLeft: '1rem'}}>Loading...</div>;
    }

    if (userInfoError || userFeedbackError) {
        return <div style={{marginTop: '10rem', color: '#fff', marginLeft: '1rem'}}>Error: {userInfoError || userFeedbackError}</div>;
    }
    
    return (
        <section className="profile-wrap">
            <div className="profile">
                <div className="votes">
                    <div className="upvotes">
                            <div className="status green">
                                <img src="/img/arrow.svg" 
                                     alt="Positive Feedback"
                                     height="13"/>
                            </div>
                        <span>{parseInt(userInfo?.feedbacks_received!)}</span>
                    </div>
                    <div className="downvotes">
                            <div className="status orange">
                                <img src="/img/dash.svg" 
                                     alt="Negative Feedback"
                                     height="13"/>
                            </div>
                        <span>0</span>
                    </div>
                </div>
                <div className="avatar-wrap">
                    <div className="circle">
                    <img src={userInfo?.avatar}
                        alt="avatar" 
                        height="150"
                        width="150"/>
                    </div>
                </div>
                <div className="profile-info">
                    <h1>{userInfo?.personaname}</h1>
                    <div className="input-wrap">
                        <input type="text" readOnly
                            value={`https://steamcommunity.com/profiles/${id}`}/>
                        <img src="/img/copy.svg" alt="copy" height="20"/>
                    </div>
                    <div className="input-wrap">
                        <input type="text" readOnly
                               value={`https://csgo-rep.com/profile/${id}`}/>
                        <img src="/img/copy.svg" alt="copy" height="20"/>
                    </div>
                    <div className="deals">
                        <img src="/img/handshake.svg"
                             height="30"
                             alt="Handshake"/>
                        <h4>Done Deals:</h4>
                        <p>{parseInt(userInfo?.feedbacks_given!) + parseInt(userInfo?.feedbacks_received!)}</p>
                    </div>
                    <div className="linked">
                        <p>Linked accounts:</p>
                        {userInfo?.role !== 'fake' && <a href={`https://steamcommunity.com/profiles/${id}`} target="_BLANK" rel="noreferrer">
                            <img src="/img/steam.svg" 
                                 alt="Steam"
                                 height="25"
                                 width="25"/>
                        </a>}
                        {userInfo?.role === 'fake' && <span>
                            <img src="/img/steam.svg" 
                                 alt="Steam"
                                 height="25"
                                 width="25"/>
                        </span>}

                    </div>
                </div>
            </div>
            <br />
            <Separator />
            <div className="all-deals">
                <div className="deals">
                    <img src="/img/cash.svg"
                            height="30"
                            alt="Cash"/>
                    <h4>Cash Deals:</h4>
                    <p>{parseInt(userInfo?.cash_deals_given!) + parseInt(userInfo?.cash_deals_received!)}</p>
                </div>
                <div className="deals">
                    <img src="/img/btc.svg"
                            height="30"
                            alt="Bitcoin"/>
                    <h4>Crypto Deals:</h4>
                    <p>{parseInt(userInfo?.crypto_deals_given!) + parseInt(userInfo?.crypto_deals_received!)}</p>
                </div>
                <div className="deals">
                    <img src="/img/buff.svg"
                            height="30"
                            alt="Buff"/>
                    <h4>Buff Deals:</h4>
                    <p>{parseInt(userInfo?.buff_deals_given!) + parseInt(userInfo?.buff_deals_received!)}</p>
                </div>
            </div>
            <Separator />
            <br />
            <Filter />
            {userFeedback && <Feedback feedbacks={userFeedback.receivedFeedbacks}/>}
        </section>
    )

}